<template>
  <v-app>
    <v-content>
      <v-tabs background-color="teal accent-4" color="white" grow>
        <v-tab @click="replacePath('/')">Direct</v-tab>
        <v-tab @click="replacePath('/templates')">Templates</v-tab>
        <v-tab @click="replacePath('/history')">History</v-tab>
      </v-tabs>

      <div>
        <router-view />
      </div>
    </v-content>
  </v-app>
</template>

<script>
export default {
  methods: {
    replacePath: function(path) {
      if (this.$router.currentRoute.path != path)
        this.$router.replace({ path });
    }
  }
};
</script>
