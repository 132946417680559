import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'direct',
      component: () => import(/* webpackChunkName: "direct" */ '@/views/Direct.vue'),
      props: route => ({
        phone: route.query.phone,
        text: route.query.text
      })
    },
    {
      path: '/templates',
      name: 'templates',
      component: () => import(/* webpackChunkName: "templates" */ '@/views/Templates.vue')
    },
    {
      path: '/history',
      name: 'history',
      component: () => import(/* webpackChunkName: "history" */ '@/views/History.vue')
    }
  ]
})
